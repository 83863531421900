var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "font-medium rounded border transition-all flex gap-2 items-center justify-center",
    class: [_vm.buttonSize, _vm.buttonType, {
      disabled: _vm.disabled
    }],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_vm.icon ? _c(_vm.icon, {
    tag: "component"
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.buttonText) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }