var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isInternal ? _c('router-link', {
    staticClass: "whitespace-nowrap text-center inline-block font-medium rounded border transition-all",
    class: [_vm.buttonSize, _vm.buttonType, {
      disabled: _vm.disabled
    }],
    attrs: {
      "to": !_vm.disabled ? _vm.url : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]) : _c('a', {
    staticClass: "text-center whitespace-nowrap inline-block font-medium rounded border transition-all",
    class: [_vm.buttonSize, _vm.buttonType, {
      disabled: _vm.disabled
    }],
    attrs: {
      "href": !_vm.disabled ? _vm.url : '',
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText))]);

}
var staticRenderFns = []

export { render, staticRenderFns }